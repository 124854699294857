import { Route, Redirect } from 'react-router-dom';
import { getUser } from '../services/identity.services';

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => {
    const evaluator = (rest.loginRoute) ? !getUser() : getUser();
    const redirectLink = (rest.loginRoute) ? '/' : '/login';
    return (
        <Route
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            render={(props) => (
                evaluator
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    ? <Component {...props} />
                    // eslint-disable-next-line react/prop-types
                    : <Redirect to={{ pathname: redirectLink, state: { from: props.location } }} />
            )}
        />
    );
};

export default PrivateRoute;
