/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ToastContainer } from 'react-toastify';
import { useStateValue } from '../../context/context';
import MenuAppbar from './menuAppbar';
import 'react-toastify/dist/ReactToastify.css';
import { Container } from './styled';

function Layout(props) {
    const [open, setOpen] = useState(false);
    const { classes } = props;

    function handleClose() {
        setOpen(false);
    }
    function handleOpen() {
        setOpen(true);
    }
    const [{ authenticated, progress }] = useStateValue();
    const { children } = props;
    useEffect(() => {
        if (!authenticated) {
            handleClose();
        }
    }, [authenticated]);
    return (
        <>
            <MenuAppbar
                authenticated={authenticated}
                open={open}
                classes={classes}
                handleOpen={handleOpen}
            />
            <Container>
                <div />
                {progress && (
                    <LinearProgress variant="query" />
                )}
                {children}
                <ToastContainer />
            </Container>

        </>
    );
}

export default Layout;
