import styled from 'styled-components';
import { Field } from 'react-final-form';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 0 12px;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const StyledField = styled(Field)`
    width: 49%;
`;
