import styled from 'styled-components';
import { Card, Button } from 'react-rainbow-components';
import Email from '../icons/email';
import EvertransitLogo from '../icons/evertransitLogo';

export const Container = styled.section`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(/assets/images/background.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;

export const Logo = styled(EvertransitLogo)`
    width: 190px;
    height: 50px;
    margin-bottom: 30px;
`;

export const Title = styled.h1`
    font-size: 32px;
    font-weight: 100;
    margin-bottom: 8px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Footer = styled.h2`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-top: 4px;
    position: absolute;
    bottom: 12px;
`;

export const CardContent = styled(Card)`
    width: 420px;
    padding: 36px;
    margin-bottom: 48px;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 12px;
    color: #fff;
`;

export const InputIcon = styled(Email)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;
