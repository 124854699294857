import requestDecorator from '../helpers/request-decorator';

function getGroups(params) {
    return requestDecorator('GET', '/admin/groups', null, params)
        .then((groups) => groups);
}

function getGroup(groupId) {
    return requestDecorator('GET', `/admin/group/${groupId}`)
        .then((group) => group);
}

function updateGroup(groupId, body) {
    return requestDecorator('PUT', `/admin/group/${groupId}`, body)
        .then((group) => group);
}

function createGroup(body) {
    return requestDecorator('POST', '/group/create_company', body)
        .then((group) => group);
}

const groupService = {
    getGroups, updateGroup, getGroup, createGroup,
};

export default groupService;
