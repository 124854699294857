/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { Button, RenderIf } from 'react-rainbow-components';
import { useQueryClient } from 'react-query';
import { useFormState } from 'react-final-form';
import { RecordSection, RecordField, RecordDetails } from '@rainbow-modules/record';
import useMutation from '../../../services/hooks/useMutation';
import groupServices from '../../../services/group.services';
import Toggle from './toggle';
import { TabContent, ZendriveForm } from './styled';

const ZendriveFields = ({ isLoading }) => {
    const { dirty } = useFormState();

    return (
        <>
            <RecordField
                isEditable
                label="SDK Key"
                isLoading={isLoading}
                name="api_key"
            />
            <RecordField
                isEditable
                label="Analitycs Key"
                isLoading={isLoading}
                name="analytics_key"
            />
            <Button
                type="submit"
                size="small"
                label="Submit"
                className="rainbow-m-top_medium"
                disabled={!dirty}
            />
        </>
    );
};

export default function Overview({ group, isLoading }) {
    const {
        _id,
        account_type,
        country,
        settings,
        createdAt,
        parentGroup,
        settings: {
            zendrive: {
                enabled = false,
                api_key = null,
                analytics_key = null,
            } = {},
        } = {},
        payment_settings: {
            chargebee_subscription_status = 'cancelled',
            chargebee_plan_id = '',
            chargebee_subscription_id = '',
            chargebee_customer_id = '',
        } = {},
    } = group;
    const parentGroupName = parentGroup && parentGroup.name;
    const queryClient = useQueryClient();
    const zendriveInitialValues = {
        api_key,
        analytics_key,
    };

    const mutate = useMutation({
        mutation: (groupId, data) => groupServices.updateGroup(groupId, data),
        onSuccess: () => queryClient.invalidateQueries(_id),
    });

    const status = chargebee_subscription_status === 'active';

    const handleChangeFleetStatus = (event) => mutate(_id, {
        ...group,
        payment_settings: {
            ...group.payment_settings,
            chargebee_subscription_status: event.target.checked ? 'active' : 'cancelled',
        },
    });

    const handleChangeZendriveStatus = (event) => mutate(_id, {
        ...group,
        settings: {
            ...group.settings,
            zendrive: {
                ...group.settings.zendrive,
                enabled: event.target.checked,
            },
        },
    });

    const handleSubmit = async (values, { reset }) => {
        await mutate(_id, {
            ...group,
            settings: {
                ...group.settings,
                zendrive: {
                    ...group.settings.zendrive,
                    ...values,
                },
            },
        });
        return reset();
    };

    return (
        <TabContent>
            <RecordSection label="Details">
                <RecordDetails>
                    <RecordField label="Account Type" value={account_type} isLoading={isLoading} />
                    <RecordField label="Created At" value={createdAt} type="dateTime" isLoading={isLoading} />
                    <RecordField label="Country" value={country} isLoading={isLoading} />
                    <RecordField label="Email" value={settings && settings.email} isLoading={isLoading} />
                    <RenderIf isTrue={parentGroupName}>
                        <RecordField label="Parent Group" value={parentGroupName} isLoading={isLoading} />
                    </RenderIf>
                    {/** TODO: add type boolean to record field */}
                    {/* <RecordField
                        label="Fleet Status"
                        value={chargebee_subscription_status}
                        isLoading={isLoading}
                    /> */}
                    <Toggle label="Fleet Status" value={status} onChange={handleChangeFleetStatus} />
                </RecordDetails>
            </RecordSection>
            <RecordSection label="Zendrive">
                <RecordDetails>
                    <Toggle label="Zendrive" value={enabled} onChange={handleChangeZendriveStatus} />
                    <ZendriveForm onSubmit={handleSubmit} initialValues={zendriveInitialValues}>
                        <ZendriveFields isLoading={isLoading} />
                    </ZendriveForm>
                </RecordDetails>
            </RecordSection>
            <RecordSection label="Chargebee">
                <RecordDetails>
                    <RecordField label="Plan" value={chargebee_plan_id} isLoading={isLoading} />
                    <RecordField label="Subscription" value={chargebee_subscription_id} isLoading={isLoading} />
                    <RecordField label="Customer" value={chargebee_customer_id} isLoading={isLoading} />
                </RecordDetails>
            </RecordSection>
        </TabContent>
    );
}
