import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, MultiSelect, TableWithBrowserPagination } from 'react-rainbow-components';

export const FleetsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 8px 12px 8px;
`;

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RightContent = styled.div`
    display: flex;
    box-sizing: border-box;
    height: 40px;
`;

export const Label = styled.h1`
    font-size: 28px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Description = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const StyledButton = styled(Button)`
    min-width: 120px;
`;

export const StyledMultiSelect = styled(MultiSelect)`
    min-width: 200px;
`;

export const StyledTable = styled(TableWithBrowserPagination)`
    width: 100%;
    padding: 0 16px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`;

export const GroupLink = styled(Link)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;
`;
