import requestDecorator from '../helpers/request-decorator';

function logout() {
    return requestDecorator('PUT', '/dispatch/logout')
        .then(() => {
            localStorage.removeItem('identity');
            return true;
        }).catch(() => {
            localStorage.removeItem('identity');
            return true;
        });
}

function login(email, password) {
    const body = {
        email,
        password,
    };
    return requestDecorator('PUT', '/dispatch/login', body)
        .then((response) => {
            // login successful if there's a jwt token in the response
            if (response.token) {
                // store user details and jwt token in local storage
                // to keep user logged in between page refreshes
                localStorage.setItem('identity', JSON.stringify(response));
            }
            return response;
        });
}

const userService = {
    login, logout,
};

export default userService;
