/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import { useQueryClient } from 'react-query';
import { RecordSection, RecordDetails, RecordField } from '@rainbow-modules/record';
import { useDoc, useUpdateDoc } from '@rainbow-modules/firebase-hooks';
import ReactJson from 'react-json-view';
import set from 'lodash/set';
import { RenderIf } from 'react-rainbow-components';
import useMutation from '../../../services/hooks/useMutation';
import groupFeaturesService from '../../../services/groupFeatures.services';
import firebase from '../../../firebase';
import Toggle from './toggle';
import { JsonContainer, StyledDescription, TabContent } from './styled';

const defaultGroupfeatures = {
    permissions: {
        dispatcher: {
            billing: {
                enabled: true,
            },
            customers: {
                enabled: true,
            },
            drivers: {
                enabled: true,
            },
            vehicles: {
                enabled: false,
            },
        },
    },
    customFeatures: {
        dispatchApp: {
            sortMapByRideEndTime: false,
            hideQuotesAndPrice: false,
        },
    },
    notifications: {
        users: {
            recieveText: true,
        },
        useTwilioMessagingService: false,
    },
    quickbooks: {
        enabled: false,
    },
    ridesListAggregate: false,
    enableRecurringRide: false,
    multiStops: {
        enabled: false,
    },
};

const GnetIntegrationEmptyText = ({ value }) => <StyledDescription>{value}</StyledDescription>;

export default function Features({ group }) {
    const gnetPath = `apps/gnet/groups/${group._id}`;

    const queryClient = useQueryClient();
    const { mutate: mutateDoc } = useUpdateDoc();
    const [data] = useDoc({
        path: `apps/api/groups/${group._id}`,
        flat: true,
    });
    const [gnetIntegrationData] = useDoc({
        path: gnetPath,
        flat: true,
    });
    const hasGnetIntegration = Boolean(gnetIntegrationData);
    const gnetIntegrationStatus = gnetIntegrationData?.status;
    const isGnetIntegrationAccepted = gnetIntegrationStatus === 'accepted';
    const gnetData = gnetIntegrationData?.gnetData;
    const isApiEnabled = Boolean(data && data.enabledApiKey);
    const groupFeaturesExists = Boolean(group.groupfeatures);

    const groupfeatures = {
        group: group._id,
        ...defaultGroupfeatures,
        ...group.groupfeatures,
    };

    const toggleGnetIntegration = async () => {
        if (isGnetIntegrationAccepted) {
            await mutateDoc({ path: gnetPath, data: { status: 'pending' } });
        } else {
            await mutateDoc({ path: gnetPath, data: { status: 'accepted' } });
        }
    };

    const toggleAPIStatus = useMutation({
        mutation: (enabled) => {
            if (enabled) {
                return firebase.functions().httpsCallable('admin-disableAPI')({ groupId: group._id });
            }
            return firebase.functions().httpsCallable('admin-enableAPI')({ groupId: group._id });
        },
    });

    const mutate = useMutation({
        mutation: (event, features, featureExists) => {
            const { checked, name } = event.target;
            const newGroupFeatures = {
                ...features,
            };
            set(newGroupFeatures, name, !checked);
            if (featureExists) {
                return groupFeaturesService.updateGroupFeatures(group._id, newGroupFeatures);
            }
            return groupFeaturesService.createGroupFeatures(newGroupFeatures);
        },
        onSuccess: () => queryClient.invalidateQueries(group._id),
    });

    const handleOnChange = (event) => mutate(event, groupfeatures, groupFeaturesExists);

    return (
        <TabContent>
            <RecordSection label="Dispatcher Permissions">
                <RecordDetails>
                    <Toggle
                        label="Billing"
                        value={groupfeatures.permissions.dispatcher.billing.enabled}
                        name="permissions.dispatcher.billing.enabled"
                        onChange={handleOnChange}
                    />
                    <Toggle
                        label="Customers"
                        value={groupfeatures.permissions.dispatcher.customers.enabled}
                        name="permissions.dispatcher.customers.enabled"
                        onChange={handleOnChange}
                    />
                    <Toggle
                        label="Drivers"
                        value={groupfeatures.permissions.dispatcher.drivers.enabled}
                        name="permissions.dispatcher.drivers.enabled"
                        onChange={handleOnChange}
                    />
                    <Toggle
                        label="Vehicles"
                        value={groupfeatures.permissions.dispatcher.vehicles.enabled}
                        name="permissions.dispatcher.vehicles.enabled"
                        onChange={handleOnChange}
                    />
                </RecordDetails>
            </RecordSection>
            <RecordSection label="Dispatch App Custom Features">
                <RecordDetails>
                    <Toggle
                        label="Sort Driver's map by ride end time"
                        value={groupfeatures.customFeatures.dispatchApp.sortMapByRideEndTime}
                        name="customFeatures.dispatchApp.sortMapByRideEndTime"
                        onChange={handleOnChange}
                    />
                    <Toggle
                        label="Hide quote button and price in email"
                        value={groupfeatures.customFeatures.dispatchApp.hideQuotesAndPrice}
                        name="customFeatures.dispatchApp.hideQuotesAndPrice"
                        onChange={handleOnChange}
                    />
                </RecordDetails>
            </RecordSection>
            <RecordSection label="User Notifications">
                <RecordDetails>
                    <Toggle
                        label="Receive Text"
                        value={groupfeatures.notifications.users.recieveText}
                        name="notifications.users.recieveText"
                        onChange={handleOnChange}
                    />
                    <Toggle
                        label="Use Twilio Messaging Service"
                        value={groupfeatures.notifications.useTwilioMessagingService}
                        name="notifications.useTwilioMessagingService"
                        onChange={handleOnChange}
                    />
                </RecordDetails>
            </RecordSection>
            <RecordSection label="Quickbooks">
                <RecordDetails>
                    <Toggle
                        label="Status"
                        value={groupfeatures.quickbooks.enabled}
                        name="quickbooks.enabled"
                        onChange={handleOnChange}
                    />
                </RecordDetails>
            </RecordSection>
            <RecordSection label="Recurring Rides">
                <RecordDetails>
                    <Toggle
                        label="Status"
                        value={groupfeatures.enableRecurringRide}
                        name="enableRecurringRide"
                        onChange={handleOnChange}
                    />
                </RecordDetails>
            </RecordSection>
            <RecordSection label="Multi Stops">
                <RecordDetails>
                    <Toggle
                        label="Status"
                        value={groupfeatures.multiStops.enabled}
                        name="multiStops.enabled"
                        onChange={handleOnChange}
                    />
                </RecordDetails>
            </RecordSection>
            <RecordSection label="API">
                <RecordDetails>
                    <Toggle
                        label="Status"
                        value={isApiEnabled}
                        onChange={() => toggleAPIStatus(isApiEnabled)}
                    />
                </RecordDetails>
            </RecordSection>
            <RecordSection label="GNet Integration">
                <RecordDetails>
                    <RenderIf isTrue={!hasGnetIntegration}>
                        <RecordField label="Approve Integration" value="This fleet is not connected to GNet yet." component={GnetIntegrationEmptyText} />
                    </RenderIf>
                    <RenderIf isTrue={hasGnetIntegration}>
                        <Toggle
                            label="Status"
                            value={isGnetIntegrationAccepted}
                            onChange={toggleGnetIntegration}
                        />
                        <JsonContainer>
                            <ReactJson
                                src={gnetData}
                                name={null}
                                theme="monokai"
                                displayDataTypes={false}
                                indentWidth={4}
                                iconStyle="triangle"
                            />
                        </JsonContainer>
                    </RenderIf>
                </RecordDetails>
            </RecordSection>
        </TabContent>
    );
}
