/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { isRequired, isEmail, composeValidators } from '@rainbow-modules/forms';
import { isPhoneNumber } from '@rainbow-modules/validation';
import { RecordSection } from '@rainbow-modules/record';
import {
    Input, CheckboxToggle, PhoneInput, RenderIf, Picklist, Option,
} from 'react-rainbow-components';
import groupService from '../../../services/group.services';
import countryList from './countryList';
import { Row, StyledField } from './styled';

const countryInitialValue = {
    name: 'US',
    label: 'United States of America (the)',
};

const formValidator = (message) => (value) => {
    const countryCode = value?.countryCode;
    const phoneNumber = value?.phone;
    if (phoneNumber) {
        const isValidNumber = isPhoneNumber(countryCode + phoneNumber);
        return isValidNumber ? undefined : message;
    }
    return undefined;
};

const Fields = () => {
    const [parentGroups, setParentGroups] = useState([]);
    const franchise = useField('makeFranchise');
    const isFranchiseChecked = !!franchise.input.value;

    const getPotentialParents = () => {
        groupService
            .getGroups({
                fleet_status: 'active',
                account_type: 'dispatch',
                nonFranchise: true,
            })
            .then((groups) => {
                setParentGroups(groups);
            });
    };

    const parentGroupInitialValue = {
        name: parentGroups[0]?._id,
        label: parentGroups[0]?.name,
    };

    useEffect(() => {
        getPotentialParents();
    }, []);

    return (
        <>
            <RecordSection label="Details">
                <Row className="rainbow-m-bottom_large">
                    <StyledField
                        label="Fleet Name"
                        component={Input}
                        required
                        name="name"
                        labelAlignment="left"
                        placeholder="Enter the fleet name"
                        validate={isRequired()}
                    />
                    <StyledField
                        label="Country"
                        component={Picklist}
                        required
                        name="country"
                        labelAlignment="left"
                        validate={isRequired()}
                        enableSearch
                        initialValue={countryInitialValue}
                    >
                        <Option
                            name="header"
                            label="Select Country"
                            variant="header"
                        />
                        {countryList.map((country) => (
                            <Option
                                key={country.code}
                                name={country.code}
                                label={country.name}
                            />
                        ))}
                    </StyledField>
                </Row>
                <Row>
                    <StyledField
                        label="Appears on Statement as"
                        component={Input}
                        required
                        name="settings.appears_on_statement_as"
                        labelAlignment="left"
                        validate={isRequired()}
                    />
                    <StyledField
                        label="Email"
                        component={Input}
                        required
                        name="settings.email"
                        labelAlignment="left"
                        placeholder="Enter email"
                        validate={composeValidators(isRequired(), isEmail())}
                    />
                </Row>
            </RecordSection>
            <RecordSection label="Chargebee">
                <StyledField
                    label="Chargebee Subscription Id"
                    component={Input}
                    required
                    name="subscription_id"
                    labelAlignment="left"
                    placeholder="Enter Chargebee Subscription Id"
                    className="rainbow-m-bottom_large"
                    validate={isRequired()}
                />
                <StyledField
                    label="Chargebee Customer Id"
                    component={Input}
                    required
                    name="customer_id"
                    labelAlignment="left"
                    placeholder="Enter Chargebee Customer Id"
                    className="rainbow-m-bottom_large"
                    validate={isRequired()}
                />
                <StyledField
                    label="Chargebee Plan Id"
                    component={Input}
                    required
                    name="plan_id"
                    labelAlignment="left"
                    placeholder="Enter Chargebee Plan Id"
                    validate={isRequired()}
                />
            </RecordSection>
            <RecordSection label="Owner">
                <Row className="rainbow-m-bottom_large">
                    <StyledField
                        label="First Name"
                        component={Input}
                        required
                        name="owner.firstName"
                        labelAlignment="left"
                        placeholder="Enter the owner first name"
                        validate={isRequired()}
                    />
                    <StyledField
                        label="Last Name"
                        component={Input}
                        required
                        name="owner.lastName"
                        labelAlignment="left"
                        placeholder="Enter the owner last name"
                        validate={isRequired()}
                    />
                </Row>
                <Row className="rainbow-m-bottom_large">
                    <StyledField
                        label="Email"
                        component={Input}
                        required
                        name="owner.email"
                        labelAlignment="left"
                        placeholder="Enter the owner email"
                        validate={isRequired()}
                    />
                    <StyledField
                        label="Phone"
                        component={PhoneInput}
                        name="owner.phone"
                        labelAlignment="left"
                        placeholder="Enter the owner phone"
                        validate={formValidator(
                            'Please enter a valid phone number.',
                        )}
                    />
                </Row>
                <StyledField
                    label="Password"
                    component={Input}
                    required
                    name="owner.password"
                    labelAlignment="left"
                    placeholder="Enter the password"
                    className="rainbow-m-bottom_large"
                    type="password"
                    validate={isRequired()}
                />
                <StyledField
                    label="Make Franchise"
                    component={CheckboxToggle}
                    required
                    name="makeFranchise"
                    className="rainbow-m-bottom_large"
                />
                <RenderIf isTrue={isFranchiseChecked}>
                    <StyledField
                        label="Parent Fleet"
                        component={Picklist}
                        required
                        initialValue={parentGroupInitialValue}
                        name="parentGroup"
                        labelAlignment="left"
                        validate={isRequired()}
                        enableSearch
                    >
                        <Option
                            name="header"
                            label="Select Parent"
                            variant="header"
                        />
                        {parentGroups.map((parent) => (
                            <Option
                                key={parent._id}
                                name={parent._id}
                                label={parent.name}
                            />
                        ))}
                    </StyledField>
                </RenderIf>
            </RecordSection>
        </>
    );
};

export default Fields;
