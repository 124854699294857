import { CheckboxToggle } from 'react-rainbow-components';
import { RecordContainer, RecordLabel } from './styled';

const Toggle = ({
    // eslint-disable-next-line react/prop-types
    label, value, onChange, name,
}) => (
    <RecordContainer>
        <RecordLabel>{label}</RecordLabel>
        <CheckboxToggle
            value={value}
            onChange={onChange}
            name={name}
        />
    </RecordContainer>
);

export default Toggle;
