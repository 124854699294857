import PropTypes from 'prop-types';
import {
    UniversalForm,
} from '@rainbow-modules/forms';
import { useMutationFlow } from '@rainbow-modules/hooks';
import groupService from '../../../services/group.services';
import formatCreateFleetFormData from '../../../helpers/formatCreateFleetFormData';
import Fields from './fields';

export default function CreateFleetForm({ onRequestClose }) {
    const handleSubmit = async (values) => {
        const formattedData = formatCreateFleetFormData(values);
        onRequestClose();
        return groupService.createGroup(formattedData);
    };

    const { mutate } = useMutationFlow({
        mutation: handleSubmit,
        submitSpinnerMessage: 'Creating a new Group',
        successMessage: (res) => `The group ${res.name} was created successfully.`,
        errorMessage: (res) => `Error creating group. Error details: ${res.error}`,
    });

    return (
        <UniversalForm className="rainbow-m-horizontal_small" id="create-fleet-form" onSubmit={mutate}>
            <Fields />
        </UniversalForm>
    );
}

CreateFleetForm.propTypes = {
    onRequestClose: PropTypes.func,
};

CreateFleetForm.defaultProps = {
    onRequestClose: () => {},
};
