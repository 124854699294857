/* eslint-disable camelcase */
const defaultPhoneNumber = '12345678900';
const defaultCountryCode = '+1';

const formatCreateFleetFormData = (formData) => {
    const {
        parentGroup,
        owner,
        country,
        customer_id,
        name,
        plan_id,
        settings,
        subscription_id,
    } = formData;

    const {
        phone = {
            countryCode: defaultCountryCode,
            isoCode: 'us',
            phone: defaultPhoneNumber,
        },
    } = owner;

    const finalPhone = phone.phone ? `${phone.countryCode}${phone.phone}` : `${defaultCountryCode}${defaultPhoneNumber}`;
    const finalParentGroup = parentGroup?.name || null;

    return {
        parentGroup: finalParentGroup,
        owner: { ...owner, phone: finalPhone },
        country: country.name,
        customer_id,
        name,
        plan_id,
        settings,
        subscription_id,
    };
};

export default formatCreateFleetFormData;
