import utils from '../helpers/utils';
import authConstants from '../constants/auth.constants';

const authReducer = utils.createSetterReducer({
    setAction: authConstants.LOGIN,
    defaultSetActionValue: true,
    initValue: false,
    clearAction: authConstants.LOGOUT,
});

export default authReducer;
