/* eslint-disable no-underscore-dangle */
import { useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import {
    Option, Column, Drawer, Button,
} from 'react-rainbow-components';
import { FloatingSearchButtonIcon, filterByFields } from '@rainbow-modules/listview';
import debounce from 'react-rainbow-components/libs/debounce';
import get from 'lodash/get';
import groupService from '../../services/group.services';
import CreateFleetForm from './create-fleet-form';
import {
    Header,
    Label,
    RightContent,
    StyledButton,
    StyledMultiSelect,
    StyledTable,
    FleetsContainer,
    Description,
    LeftContent,
    GroupLink,
} from './styled';

const booleanDisplayValue = (value, trueDisplay = 'Enabled', falseDisplay = 'Disabled') => (value ? trueDisplay : falseDisplay);

const checkGroupFeature = (group, featurePath, defaultValue = false) => get(group, `groupfeatures.${featurePath}`, defaultValue);

const checkGroupDispatcherFeature = (group, featurePath, defaultValue = true) => {
    const dispatcherPermissionsPath = 'permissions.dispatcher';
    return checkGroupFeature(group, dispatcherPermissionsPath)
        ? checkGroupFeature(group, `${dispatcherPermissionsPath}.${featurePath}`) : defaultValue;
};

function GroupTable() {
    const [fleetStatus, setFleetStatus] = useState([{ name: 'active', label: 'active' }]);
    const [accountType, setAccountType] = useState([{ name: 'dispatch', label: 'dispatch' }]);
    const [query, setQuery] = useState();
    const [searchQuery, setSearchQuery] = useState();
    const [isOpen, setOpenDrawer] = useState(false);
    const {
        data = [],
        isLoading: progress,
    } = useQuery(
        [
            'groups',
            fleetStatus,
            accountType,
        ],
        () => groupService.getGroups({
            fleet_status: fleetStatus.map((status) => status.name),
            account_type: accountType.map((type) => type.name),
        }),
    );
    const groups = useMemo(() => filterByFields({
        data,
        query: searchQuery,
        fields: ['name'],
    }), [data, searchQuery]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchWithDebounce = useCallback(debounce(setSearchQuery, 500), []);

    const handleSearchQuery = useCallback((value) => {
        setQuery(value);
        searchWithDebounce(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FleetsContainer>
            <Header>
                <LeftContent>
                    <Label>Fleets</Label>
                    <Description>{`Amount • ${data.length}`}</Description>
                </LeftContent>
                <RightContent>
                    <FloatingSearchButtonIcon
                        onChange={handleSearchQuery}
                        value={query}
                        className="rainbow-m-right_medium"
                    />
                    <StyledMultiSelect
                        value={fleetStatus}
                        onChange={setFleetStatus}
                        placeholder="Fleet Status"
                        className="rainbow-m-right_medium"
                    >
                        <Option name="active" label="active">
                            Active
                        </Option>
                        <Option name="cancelled" label="cancelled">
                            Cancelled
                        </Option>
                        <Option name="notExists" label="notExists">
                            {'Doesn\'t Exist'}
                        </Option>
                    </StyledMultiSelect>
                    <StyledMultiSelect
                        placeholder="Account Type"
                        value={accountType}
                        onChange={setAccountType}
                        className="rainbow-m-right_medium"
                    >
                        <Option name="dispatch" label="dispatch">
                            Dispatch
                        </Option>
                        <Option name="affiliate" label="affiliate">
                            Affiliate
                        </Option>
                        <Option name="concierge" label="concierge">
                            Concierge
                        </Option>
                    </StyledMultiSelect>
                    <StyledButton
                        label="New Fleet"
                        variant="outline-brand"
                        onClick={() => setOpenDrawer(true)}
                    />
                </RightContent>
            </Header>
            <StyledTable
                keyField="id"
                pageSize={50}
                data={groups}
                isLoading={progress}
                variant="listview"
            >
                <Column
                    header="Name"
                    field="name"
                    cellAlignment="left"
                    component={({ value, row }) => (
                        <GroupLink to={`/group/${row._id}`}>
                            {value}
                        </GroupLink>
                    )}
                />
                <Column header="Account Type" width={120} field="account_type" />
                <Column
                    header="Status"
                    width={90}
                    field="payment_settings.chargebee_subscription_status"
                />
                <Column
                    header="Quikbooks"
                    width={120}
                    field="quickbooks.enabled"
                    component={({ row }) => booleanDisplayValue(checkGroupFeature(row, 'quickbooks.enabled'), 'Yes', 'No')}
                />
                <Column
                    header="Sort Driver's map by ride end time"
                    width={120}
                    field="customFeatures.dispatchApp.sortMapByRideEndTime"
                    component={({ row }) => booleanDisplayValue(checkGroupFeature(row, 'customFeatures.dispatchApp.sortMapByRideEndTime'))}
                />
                <Column
                    header="User Notifications - Recieve Text"
                    width={120}
                    field="notifications.users.recieveText"
                    component={({ row }) => booleanDisplayValue(checkGroupFeature(row, 'notifications.users.recieveText', true))}
                />
                <Column
                    header="Dispatcher permission - Billing"
                    width={120}
                    field="billing.enabled"
                    component={({ row }) => booleanDisplayValue(checkGroupDispatcherFeature(row, 'billing.enabled'))}
                />
                <Column
                    header="Dispatcher permission - Customers"
                    width={120}
                    field="customers.enabled"
                    component={({ row }) => booleanDisplayValue(checkGroupDispatcherFeature(row, 'customers.enabled'))}
                />
                <Column
                    header="Dispatcher permission - Drivers"
                    width={120}
                    field="drivers.enabled"
                    component={({ row }) => booleanDisplayValue(checkGroupDispatcherFeature(row, 'drivers.enabled'))}
                />
                <Column
                    header="Dispatcher permission - Vehicles"
                    width={120}
                    field="vehicles.enabled"
                    component={({ row }) => booleanDisplayValue(checkGroupDispatcherFeature(row, 'vehicles.enabled', false))}
                />
            </StyledTable>
            <Drawer
                header="Create New Fleet"
                slideFrom="right"
                isOpen={isOpen}
                size="medium"
                onRequestClose={() => setOpenDrawer(false)}
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button
                            className="rainbow-m-right_medium"
                            label="Cancel"
                            variant="base"
                            onClick={() => setOpenDrawer(false)}
                        />
                        <Button label="Create" variant="brand" form="create-fleet-form" type="submit" />
                    </div>
                )}
            >
                <CreateFleetForm onRequestClose={() => setOpenDrawer(false)} />
            </Drawer>
        </FleetsContainer>
    );
}

export default GroupTable;
