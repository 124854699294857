import handleResponse from './response-handler';
import requestHeader from './request-header';

function requestDecorator(methodType, partialUrl, body, params) {
    const requestOptions = {
        method: methodType,
        headers: requestHeader(),
    };
    let url = `${process.env.REACT_APP_API_URL}${partialUrl}`;
    if (params) {
        const query = Object.keys(params)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        url = `${url}?${query}`;
    }
    if (body) {
        requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions)
        .then(handleResponse);
}

export default requestDecorator;
