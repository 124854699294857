import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UniversalForm } from '@rainbow-modules/forms';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 0 12px;
`;

export const BackButton = styled(Link)`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    margin: 16px 0;
    font-size: 14px;
    width: fit-content;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.brand.main};
    }
`;

export const TabContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`;

export const RecordContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
    line-height: 20px;
`;

export const RecordLabel = styled.span`
    line-height: 1.5;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    flex: 0 0 20%;
    min-width: 200px;
    max-width: 260px;
    padding-right: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
`;

export const ZendriveForm = styled(UniversalForm)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const StyledDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-style: italic;
`;

export const JsonContainer = styled.div`
    margin: 10px 0 0;
`;
