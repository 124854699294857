import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

const rootEl = document.getElementById('root');

ReactDOM.render(
    <App />,
    rootEl,
);

if (module.hot) {
    module.hot.accept('./App', () => {
    // eslint-disable-next-line global-require
        const NextApp = require('./App').default;
        ReactDOM.render(
            <NextApp />,
            rootEl,
        );
    });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
