import { combineReducers } from 'redux';
import progressReducer from './progress.reducer';
import authReducer from './auth.reducer';
import userReducer from './user.reducer';

const reducer = combineReducers({
    authenticated: authReducer,
    progress: progressReducer,
    user: userReducer,
});

export default reducer;
