const setObjectValue = (obj, access, value) => {
    if (typeof (access) === 'string') {
        // eslint-disable-next-line no-param-reassign
        access = access.split('.');
    }
    if (access.length > 1) {
        setObjectValue(obj[access.shift()], access, value);
    } else {
        // eslint-disable-next-line no-param-reassign
        obj[access[0]] = value;
    }
};

const createSetterReducer = (options) => (state = options.initValue, action) => {
    switch (action.type) {
        case options.setAction:
            return action.value ? action.value : options.defaultSetActionValue;
        case options.clearAction:
            return options.initValue;
        default:
            return state;
    }
};

const utils = {
    setObjectValue,
    createSetterReducer,
};

export default utils;
