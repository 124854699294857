import { useState } from 'react';
import { StateProvider } from './context';
import { getUser } from '../services/identity.services';
import reducer from '../reducers';

function GlobalProvider(props) {
    const [authenticated] = useState(!!getUser());
    const [user] = useState(getUser());
    const [progress] = useState(false);
    // eslint-disable-next-line react/prop-types
    const { children } = props;
    return (
        <StateProvider
            initialState={{
                authenticated, user, progress,
            }}
            reducer={reducer}
        >
            {children}
        </StateProvider>

    );
}

export default GlobalProvider;
