import requestDecorator from '../helpers/request-decorator';

function getAll(group) {
    return requestDecorator('GET', `/reporting?status=notsubmitted&status=queued&status=running&isCompleted=false&group=${group}`);
}

function create(body) {
    return requestDecorator('POST', '/reporting', body);
}

function updateReport(reportId, body) {
    return requestDecorator('PATCH', `/reporting/admin/${reportId}`, body);
}

const reportsServices = {
    getAll, create, updateReport,
};

export default reportsServices;
