import requestDecorator from '../helpers/request-decorator';

function getGroupFeatures(groupId) {
    return requestDecorator('GET', `/group-features/${groupId}`)
        .then((groupFeature) => groupFeature);
}

function updateGroupFeatures(groupId, body) {
    return requestDecorator('PUT', `/group-features/${groupId}`, body)
        .then((groupFeature) => groupFeature);
}

function createGroupFeatures(body) {
    return requestDecorator('POST', '/group-features', body)
        .then((groupFeature) => groupFeature);
}

const groupFeaturesService = { getGroupFeatures, updateGroupFeatures, createGroupFeatures };

export default groupFeaturesService;
