import PropTypes from 'prop-types';

export default function Lock(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="341px" height="385px" viewBox="0 0 341 385">
            <g id="app-import-export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M295,140.901355 C321,144.001355 340,165.901355 340.1,192.201355 L340.1,192.201355 L340.1,332.65985 C340.1,361.15985 317.3,384.65985 288.8,384.65985 L288.8,384.65985 L51.3,384.65985 C22.8,384.65985 0,361.15985 0,332.65985 L0,332.65985 L0,192.201355 C0,165.801355 19,144.001355 45,140.901355 L45,140.901355 L60.999,140.901327 L61,106.200682 L61.1,106.200682 C61.1,47.8006821 113.2,1.30594913e-09 171.1,1.30594913e-09 L171.2,1.30594913e-09 C198.7,-0.0993179455 229.1,10.8006821 248.6,30.2006821 C268.9,50.3006821 280.2,77.7006821 280,106.200682 L280,140.901327 L258,140.901327 L258,141.000682 L280,141.000682 L280,140.901327 Z M289.3,161.001355 L51.7,161.001355 C34.2,161.001355 20,175.201355 20,192.701355 L20,192.701355 L20,332.95985 C20,350.45985 34.2,364.65985 51.7,364.65985 L51.7,364.65985 L289.3,364.65985 C306.8,364.65985 321,350.45985 321,332.95985 L321,332.95985 L321,192.701355 C321,175.201355 306.8,161.001355 289.3,161.001355 L289.3,161.001355 Z M170,214.301355 C186.1,214.301355 200.2,224.901355 204.6,240.401355 C210.2,259.501355 199.1,279.501355 180,285.001355 L180,285.001355 L180,313.001355 C180,318.501355 175.5,323.001355 170,323.001355 C164.5,323.001355 160,318.501355 160,313.001355 L160,313.001355 L160,285.001355 C144.5,280.601355 133.9,266.501355 133.9,250.401355 C133.9,230.401355 150.1,214.301355 170,214.301355 Z M170,234.201355 C161.1,234.201355 153.9,241.401355 153.9,250.301355 C153.9,259.201355 161.1,266.401355 170,266.401355 C178.9,266.401355 186.1,259.201355 186.1,250.301355 C186.1,241.401355 178.9,234.201355 170,234.201355 Z M82.999,140.901327 L60.999,140.901327 L61,141.000682 L83,141.000682 L82.999,140.901327 Z M171.8,21.9006821 L171.2,21.9006821 C125.2,21.9006821 83,59.7006821 83,106.100682 L82.999,140.901327 L258,140.901327 L258,106.200682 C258.2,83.5006821 249.2,61.7006821 233.1,45.7006821 C217.9,30.5006821 193.3,21.9006821 171.8,21.9006821 Z" id="lock" fill="currentColor" fillRule="nonzero" />
            </g>
        </svg>
    );
}

Lock.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Lock.defaultProps = {
    style: undefined,
    className: undefined,
};
