/* eslint-disable react/prop-types */
import React from 'react';
import { Input } from 'react-rainbow-components';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { useStateValue } from '../../context/context';
import history from '../../routes/history';
import authWithFirebase from '../../services/firebase/authWithFirebase';
import userService from '../../services/user.services';
import authConstants from '../../constants/auth.constants';
import userConstants from '../../constants/user.constants';
import {
    Container, CardContent, InputIcon, StyledButton, Title, Logo, Footer,
} from './styled';
import Lock from '../icons/lock';

const getYear = () => new Date().getFullYear();

function Login() {
    const [, dispatch] = useStateValue();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loggingIn, setLoggingIn] = React.useState(false);

    const login = async (_email, _password) => {
        try {
            const response = await userService.login(_email, _password);
            const { user, token } = response;
            showAppSpinner();
            await authWithFirebase(token);
            hideAppSpinner();
            dispatch({
                type: authConstants.LOGIN,
            });
            dispatch({
                type: userConstants.SET,
                value: user,
            });
            showAppNotification({
                title: 'Success',
                description: 'Welcome!',
                icon: 'success',
            });
            history.push('/');
        } catch (error) {
            showAppNotification({
                title: 'Invalid Credentials.',
                description: 'Failed login',
                icon: 'error',
            });
            setLoggingIn(false);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoggingIn(true);
        login(email, password);
    };

    return (
        <Container>
            <Logo />
            <Title>
                Sign in
            </Title>
            <CardContent>
                <form noValidate onSubmit={handleSubmit}>
                    <Input
                        type="email"
                        label="Email"
                        placeholder="Enter your Email Address"
                        onChange={(event) => setEmail(event.target.value)}
                        icon={<InputIcon />}
                        className="rainbow-m-bottom_x-large"
                        required
                        autoComplete="off"
                    />
                    <Input
                        type="password"
                        label="Password"
                        placeholder="Enter your password"
                        onChange={(event) => setPassword(event.target.value)}
                        icon={<InputIcon as={Lock} />}
                        className="rainbow-m-vertical_large"
                        required
                        autoComplete="off"
                    />
                    <StyledButton
                        label="Sign In"
                        type="submit"
                        isLoading={loggingIn}
                        variant="brand"
                    />
                </form>
            </CardContent>
            <Footer>
                {`© ${getYear()} EverTransit LLC`}
            </Footer>
        </Container>
    );
}

export default Login;
