import { useCallback } from 'react';
import { confirmModal } from '@rainbow-modules/app';
import { useMutationFlow } from '@rainbow-modules/hooks';

const useMutation = ({ onSuccess = () => {}, mutation = () => {} }) => {
    const { mutate } = useMutationFlow({
        mutation,
        successMessage: 'Group updated successfully',
        errorMessage: (error) => error.toString(),
        onSuccess,
    });

    return useCallback(async (...args) => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Confirmation',
            question: 'Are you sure you want to proceed?',
            okButtonLabel: 'Ok',
        });
        if (result) {
            await mutate(...args)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useMutation;
