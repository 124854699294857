import { toast } from 'react-toastify';

const successNotification = (notificationMessage) => {
    toast.success(notificationMessage);
};

const errorNotification = (notificationMessage) => {
    toast.error(notificationMessage);
};

const notification = {
    successNotification,
    errorNotification,
};

export default notification;
