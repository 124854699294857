/* eslint-disable camelcase */
import { useState } from 'react';
import { RecordHeader, RecordPrimaryDetails, RecordField } from '@rainbow-modules/record';
import { Tabset, Tab, RenderIf } from 'react-rainbow-components';
import { ArrowLeft, FrontCar } from '@rainbow-modules/icons';
import { useQuery } from 'react-query';
import groupService from '../../../services/group.services';
import Overview from './overview';
import Features from './features';
import { BackButton, Container } from './styled';

const tabContentMap = {
    overview: Overview,
    features: Features,
};

// eslint-disable-next-line react/prop-types
export default function Details({ match: { params: { groupId } } }) {
    const [selected, setSelected] = useState('overview');
    const TabContent = tabContentMap[selected];
    const {
        data: group = {},
        isLoading,
    } = useQuery(groupId, () => groupService.getGroup(groupId));
    const {
        name,
        account_type,
        country,
        settings,
        createdAt,
        parentGroup,
        payment_settings: { chargebee_subscription_status } = {},
    } = group;
    const parentGroupName = parentGroup && parentGroup.name;

    const handleOnSelect = (event, value) => {
        setSelected(value);
    };

    return (
        <Container>
            <BackButton to="/">
                <ArrowLeft className="rainbow-m-right_medium" />
                Back to Fleets
            </BackButton>
            <RecordHeader
                label="FLEET"
                description={name}
                icon={<FrontCar />}
                isLoading={isLoading}
            >
                <RecordPrimaryDetails>
                    <RecordField label="Account Type" value={account_type} isLoading={isLoading} />
                    <RecordField label="Status" value={chargebee_subscription_status} isLoading={isLoading} />
                    <RecordField label="Created At" value={createdAt} type="dateTime" isLoading={isLoading} />
                    <RecordField label="Country" value={country} isLoading={isLoading} />
                    <RecordField label="Email" value={settings && settings.email} isLoading={isLoading} />
                    <RenderIf isTrue={parentGroupName}>
                        <RecordField label="Parent Group" value={parentGroupName} isLoading={isLoading} />
                    </RenderIf>
                </RecordPrimaryDetails>
            </RecordHeader>
            <Tabset variant="line" onSelect={handleOnSelect} activeTabName={selected}>
                <Tab name="overview" label="Overview" />
                <Tab name="features" label="Features" />
            </Tabset>
            <TabContent group={group} isLoading={isLoading} />
        </Container>
    );
}
