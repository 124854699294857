/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Close from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import MUIDataTable from 'mui-datatables';
import groupService from '../../services/group.services';
import reportsServices from '../../services/reports.services';
import { useStateValue } from '../../context/context';
import progressConstants from '../../constants/progress.constants';
import notification from '../../helpers/notification';

function PendingReports() {
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [groups, setGroups] = useState([]);
    const [reports, setReports] = useState([]);
    const [{ progress }, dispatch] = useStateValue();
    const getGroups = () => {
        dispatch({
            type: progressConstants.SET_PROGRESS,
        });
        groupService.getGroups({ account_type: 'dispatch' }).then((_groups) => {
            setGroups(_groups);
            dispatch({
                type: progressConstants.REMOVE_PROGRESS,
            });
        });
    };
    const getReports = () => {
        if (selectedGroup) {
            dispatch({
                type: progressConstants.SET_PROGRESS,
            });
            reportsServices.getAll(selectedGroup._id).then((items) => {
                dispatch({
                    type: progressConstants.REMOVE_PROGRESS,
                });
                setReports(items);
            });
        }
    };
    const updateReport = (e, index) => {
        e.preventDefault();
        dispatch({
            type: progressConstants.SET_PROGRESS,
        });
        reportsServices.updateReport(reports[index]._id, { status: 'failed', isCompleted: true, queryHash: `${reports[index].queryHash}${Math.floor(Math.random() * Math.floor(999999999)).toString()}` }).then(() => {
            notification.successNotification('Successfully updated report');
            dispatch({
                type: progressConstants.REMOVE_PROGRESS,
            });
            getReports();
        }).catch((error) => {
            notification.errorNotification(`Error updating report. Error details: ${error.error}`);
            dispatch({
                type: progressConstants.REMOVE_PROGRESS,
            });
        });
    };
    const setParentGroup = (value) => {
        setSelectedGroup(value.target.value);
    };

    const columns = [
        {
            name: 'createdAt',
            label: 'Created At',
            options: {
                customBodyRender: (_value, { rowIndex }) => ((reports[rowIndex].createdAt) ? moment(reports[rowIndex].createdAt).format('MM/DD/YYYY') : ''),
            },
        },
        {
            name: 'reportType',
            label: 'Report Type',
        },
        {
            name: 'startDate',
            label: 'Start Date',
            options: {
                customBodyRender: (_value, { rowIndex }) => ((reports[rowIndex].query && reports[rowIndex].query.start) ? moment(reports[rowIndex].query.start).format('MM/DD/YYYY') : ''),
            },
        },
        {
            name: 'endDate',
            label: 'End Date',
            options: {
                customBodyRender: (_value, { rowIndex }) => ((reports[rowIndex].query && reports[rowIndex].query.end) ? moment(reports[rowIndex].query.end).format('MM/DD/YYYY') : ''),
            },
        },
        {
            name: 'status',
            label: 'Status',
        },
        {
            name: 'update',
            label: 'Mark as Failed',
            options: {
                customBodyRender: (_value, { rowIndex }) => (
                    <Close onClick={(e) => updateReport(e, rowIndex)} />
                ),
            },
        },
    ];

    const options = {
        responsive: 'scrollMaxHeight',
        print: false,
        download: false,
        selectableRows: 'none',
        sort: false,
    };
    useEffect(() => {
        getGroups();
    // eslint-disable-next-line
  }, []);

    useEffect(() => {
        setReports([]);
        getReports();
    // eslint-disable-next-line
  }, [selectedGroup]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    value={selectedGroup}
                    select
                    label="Pick a Fleet"
                    id="group"
                    margin="normal"
                    variant="outlined"
                    onChange={setParentGroup}
                    disabled={progress}
                >
                    {
                        groups && groups.map((group) => (
                            <MenuItem key={group._id} value={group}>
                                {group.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <MUIDataTable
                    title="Pending Reports"
                    data={reports}
                    columns={columns}
                    options={options}
                />
            </Grid>
        </Grid>
    );
}

export default PendingReports;
