import history from '../routes/history';

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                history.push('/logout');
            }
            return Promise.reject(data);
        }

        return data;
    });
}

export default handleResponse;
