import { Router, Switch, Redirect } from 'react-router-dom';
import { RainbowFirebaseApp } from '@rainbow-modules/app';
import { QueryClientProvider } from 'react-query';
import history from './routes/history';
import PrivateRoute from './routes/PrivateRoute';
import Layout from './component/layout/layout';
import Login from './component/login';
import GlobalProvider from './context/globalProvider';
import GroupTable from './component/admin/groupTable';
import PendingReports from './component/admin/pendingReports';
import GroupDetails from './component/admin/group-details';
import useInitApp from './services/hooks/useInitApp';
import queryClient from './queryClient';
import firebase from './firebase';

const theme = {
    rainbow: {
        palette: {
            brand: '#1baa8e',
            success: '#79c9a6',
        },
    },
};

function Routes() {
    useInitApp();
    return (
        <Router history={history}>
            <GlobalProvider>
                <QueryClientProvider client={queryClient}>
                    <Layout>
                        <Switch>
                            <PrivateRoute exact path="/" component={GroupTable} loginRoute={false} />
                            <PrivateRoute exact path="/group/:groupId" component={GroupDetails} />
                            <PrivateRoute
                                exact
                                path="/pending-reports"
                                component={PendingReports}
                                loginRoute={false}
                            />
                            <PrivateRoute path="/login" component={Login} loginRoute />
                            <Redirect to="/" />
                        </Switch>
                    </Layout>
                </QueryClientProvider>
            </GlobalProvider>
        </Router>
    );
}

function App() {
    return (
        <RainbowFirebaseApp app={firebase} theme={theme}>
            <Routes />
        </RainbowFirebaseApp>
    );
}

export default App;
