import makeRequest from '../makeRequest';
import firebase from '../../firebase';

const url = `${process.env.REACT_APP_BASE_URL}/auth/et/token`;

const signInWithCustomToken = (token) => firebase.auth().signInWithCustomToken(token);

const authWithFirebase = async (token) => {
    const response = await makeRequest({
        url,
        method: 'POST',
        data: { token },
    });
    const responseJson = await response.json();
    if (responseJson) {
        return signInWithCustomToken(responseJson.token);
    }
    return null;
};

export default authWithFirebase;
