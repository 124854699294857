import { useState, useEffect } from 'react';
import { useFirebaseApp, useAuthState } from '@rainbow-modules/firebase-hooks';

async function getUserProfile(app) {
    const userResponse = await app.functions().httpsCallable('getUserProfile')();
    return userResponse.data || {};
}

export default function useUserProfile() {
    const [userProfile, setUserProfile] = useState({});
    const app = useFirebaseApp();
    const isAuth = useAuthState();
    useEffect(() => {
        (async () => {
            if (isAuth) {
                const profile = await getUserProfile(app);
                setUserProfile(profile);
            } else {
                setUserProfile({})
            }
        })();
    }, [app, isAuth]);
    return userProfile;
}
