import styled from 'styled-components';
import { Avatar } from '@rainbow-modules/icons';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: #fbfdfd;
    width: 100%;
    padding: 0 12px;
`;

export const TopBar = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fbfdfd;
    width: 100%;
    padding: 8px 0 6px 16px;
`;

export const RightContent = styled.div`
    display: flex;
    align-items: center;
`;

export const AvatarIcon = styled(Avatar)`
    width: 100%;
    height: 100%;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const UserInfo = styled.li`
    display: flex;
    padding: 4px 12px;
    align-items: center;
`;

export const UserName = styled.div`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const UserDescription = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;
