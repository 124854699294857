import { useHistory } from 'react-router-dom';
import {
    AvatarMenu,
    Avatar,
    MenuDivider,
    MenuItem,
    RenderIf,
    // ButtonIcon,
} from 'react-rainbow-components';
import { showAppNotification } from '@rainbow-modules/app';
// import { Component } from '@rainbow-modules/icons';
import Logo from '../icons/evertransitLogo';
import { useStateValue } from '../../context/context';
import userService from '../../services/user.services';
import authConstants from '../../constants/auth.constants';
import userConstants from '../../constants/user.constants';
import firebase from '../../firebase';
import useUserProfile from '../../services/hooks/useUserProfile';
import {
    TopBar, RightContent, AvatarIcon, UserInfo, UserName, UserDescription,
} from './styled';

function MenuToolbar() {
    const { push } = useHistory();
    const [{ authenticated }, dispatch] = useStateValue();
    const { displayName, email, photoURL } = useUserProfile();

    const logutProcedure = () => {
        dispatch({
            type: authConstants.LOGOUT,
        });
        dispatch({
            type: userConstants.REMOVE,
        });
        showAppNotification({
            title: 'Success',
            description: 'Logout Successful!',
            icon: 'success',
        });
        push('/');
    };

    const logout = async () => {
        if (authenticated) {
            try {
                await userService.logout();
                await firebase.auth().signOut();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
            logutProcedure();
        }
    };

    return (
        <TopBar>
            <RenderIf isTrue={authenticated}>
                <Logo />
            </RenderIf>
            <RightContent>
                {/* <ButtonIcon
                    variant="base"
                    icon={<Component />}
                    className="rainbow-p-horizontal_medium"
                    buttonSize="large"
                /> */}
                <RenderIf isTrue={authenticated}>
                    <AvatarMenu
                        className="rainbow-m-horizontal_medium"
                        id="avatar-menu"
                        icon={<AvatarIcon />}
                        assistiveText={displayName}
                        menuAlignment="right"
                        menuSize="small"
                        avatarSize="large"
                        title={displayName}
                        avatarBackgroundColor="transparent"
                        src={photoURL}
                    >
                        <UserInfo>
                            <Avatar
                                icon={<AvatarIcon />}
                                assistiveText={displayName}
                                title={displayName}
                                size="medium"
                                backgroundColor="transparent"
                                src={photoURL}
                            />
                            <div>
                                <UserName>{displayName}</UserName>
                                <UserDescription>{email}</UserDescription>
                            </div>
                        </UserInfo>
                        <MenuDivider variant="space" />
                        <MenuItem
                            label="Logout"
                            iconPosition="left"
                            onClick={logout}
                        />
                    </AvatarMenu>
                </RenderIf>
            </RightContent>
        </TopBar>
    );
}

export default MenuToolbar;
