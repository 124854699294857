import progressConstants from '../constants/progress.constants';
import utils from '../helpers/utils';

const progressReducer = utils.createSetterReducer({
    setAction: progressConstants.SET_PROGRESS,
    initValue: false,
    defaultSetActionValue: true,
    clearAction: progressConstants.REMOVE_PROGRESS,
});

export default progressReducer;
