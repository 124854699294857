import { useEffect } from 'react';
import { useAuthState } from '@rainbow-modules/firebase-hooks';
import { hideAppSpinner, showAppSpinner } from '@rainbow-modules/app';
import { getToken } from '../identity.services';
import authWithFirebase from '../firebase/authWithFirebase';
import userService from '../../services/user.services';
import firebase from '../../firebase';

const useInitApp = () => {
    const isAuth = useAuthState();
    useEffect(() => {
        (async () => {
            if (isAuth === false && getToken()) {
                showAppSpinner();
                try {
                    await authWithFirebase(getToken());
                } catch (error) {
                    try {
                        await userService.logout();
                        await firebase.auth().signOut();
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    }
                }
                hideAppSpinner();
            }
        })()
    }, [isAuth]);
}

export default useInitApp;
