import { getToken } from '../services/identity.services';

function requestHeader() {
    const header = { 'Content-Type': 'application/json', 'dashride-api-key': `${process.env.REACT_APP_DASHRIDE_API_KEY}` };
    if (getToken()) {
        header.Authorization = `Bearer ${getToken()}`;
    }
    return header;
}

export default requestHeader;
